import type { AsVuexStore } from '@zento-lib/components/vuex';

import { IOrder } from '../order';

export const KEY = 'user';

export type IVSFUser = AsVuexStore<
{
  token: string;
  refreshToken: string;
  groupToken: string;
  groupId: any;
  current: {
    id: number;
    group_id: number;
    created_at: string;
    updated_at: string;
    created_in: string;
    email: string;
    firstname: string;
    lastname: string;
    store_id: number;
    website_id: number;
    addresses: IUserAddress[];
    disable_auto_group_change: number;
    default_billing: string;
    default_shipping: string;
    extension_attributes: Record<string, any>;
  } | null;
  current_storecode: string;
  session_started: Date;
  orders_history: null | IOrder[];
  invoice_history: any | Record<string, any>;
  local_data_loaded: boolean;
},
'isLoggedIn' | 'isLocalDataLoaded' | 'getUserToken' | 'getOrdersHistory' | 'getInvoiceHistory',
'startSession' | 'resetPassword' | 'login' | 'register' | 'refresh' | 'setUserGroup' | 'me' | 'update' |
'setCurrentUser' | 'changePassword' | 'clearCurrentUser' | 'logout' | 'fetchOrdersHistory' |
'userAfterUpdate' | 'sessionAfterAuthorized' | 'fetchInvoiceHistory',
'user/NEWSLETTER_SIGNUP' | 'user/TOKEN_CHANGED' | 'user/INFO_LOADED' | 'user/ORDERS_HISTORY_LOADED' |
'user/START_SESSION' | 'user/END_SESSION' | 'user/UPDATE_PREFERENCES' | 'user/GROUP_TOKEN_CHANGED' |
'user/GROUP_ID_CHANGED' | 'user/LOCAL_DATA_LOADED' | 'user/INVOICE_HISTORY_LOADED'
>;

export interface IUserAddress {
  id: number;
  firstname: string;
  lastname: string;
  company: string;
  street: string[];
  house: string;
  city: string;
  region: any;
  country: string;
  country_id: string;
  postcode: string;
  taxId: string;
  vat_id: string;
  telephone: string;
  default_shipping: boolean;
  default_billing: boolean;
  customer_id?: number;
  company_reg_no?: string;
  bank_name?: string;
  bank_iban?: string;
}
